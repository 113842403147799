import React from 'react';
import '../../css/home/Logos.css';
import TinderLogo from '../../img/logos/tinderlogo.svg';
import SamsungLogo from '../../img/logos/samsunglogo.png';
import AcornsLogo from '../../img/logos/acornslogo.png';
import AdidasLogo from '../../img/logos/adidaslogo.png';
import BostonGlobeLogo from '../../img/logos/bostonglobelogo.png';
import TiaLupitaLogo from '../../img/logos/tialupitalogo.png';

const Logos = () => {
  return (
    <section className="logo-section">
      <h2 className="logo-title">Our Campus Leaders</h2>
      <div className="logo-container">
        {/* Placeholder for 4 images */}
        <img src={SamsungLogo} alt="Samsung Logo" className="logo-item" />
        <img src={TinderLogo} alt="Tinder Logo" className="logo-item" />
        <img src={AcornsLogo} alt="Acorns Logo" className="logo-item" />
        <img src={AdidasLogo} alt="Adidas Logo" className="logo-item" />
        <img src={BostonGlobeLogo} alt="Boston Globe Logo" className="logo-item" />
        <img src={TiaLupitaLogo} alt="Tia Lupita Logo" className="logo-item" />
      </div>
    </section>
  );
};

export default Logos;
